import React, { useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const ExportButton = ({ data, filename = "data.xlsx", columns, onClick, loading }) => {
  const handleExport = () => {
    if (!data || data.length === 0) return;
    console.log("Datos a exportar:", data);

    // Formatear los datos para la exportación
    const formattedData = data.map((record) => {
      const formattedRecord = {};
      columns.forEach((col) => {
        // Obtener el valor anidado si la columna es de un objeto anidado
        const value = getNestedValue(record, col.source);

        // Si la columna tiene una función de transformación, úsala
        formattedRecord[col.label] = col.transform ? col.transform(value) : value;
      });

      return formattedRecord;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
  
    worksheet["!cols"] = columns.map((col) => ({ wch: col.width || 15 }));
    
    XLSX.writeFile(workbook, filename, { bookType: "xlsx", type: "binary", cellDates: true, encoding: "UTF-8" });
  };


  useEffect(() => {    
    if (!loading && data.length > 0) {
      console.log("Datos recibidos: ", data);
      handleExport();
    }
  }, [loading, data]);

  const handleClick = async () => {    
    if (data.length > 0) {
      handleExport();
    } else {
      // Si no hay datos, primero llama a la función para cargarlos
      await onClick();
    }
  };

  return (
    <Button onClick={handleClick} startIcon={<DownloadIcon />} disabled={loading}>
      {loading ? <CircularProgress size={24} /> : "Exportar"}
    </Button>
  );
};

ExportButton.propTypes = {
  data: PropTypes.array.isRequired,
  filename: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      transform: PropTypes.func,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ExportButton;

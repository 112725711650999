import React, { useState } from "react";
import { ImageField, TextInput, Filter, List as BaseList, Datagrid, TextField, BooleanField, TopToolbar, CreateButton } from "react-admin";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import ExportButton from "../components/ExcelExportButton";
import useExport from "../components/UseExcelExport";

const TitleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Título" source="title" alwaysOn />
  </Filter>
);

const ListActions = () => {
  const { getExportData, exportData, loading } = useExport("service");
  const [ openModal, setOpenModal ] = useState(false);

  const handleExportClick = async () => {
    if (!exportData.length) {      
      setOpenModal(true);
      await getExportData(); 
      setOpenModal(false);
    }
  };

  const columns = [
    { label: "Nombre", source: "name" },
    { label: "Precio Unitario(Bs.)", source: "unitPrice" },
    { label: "Descripción", source: "description" },
    { label: "Habilitado", source: "enabled", transform: (val) => (val ? "Sí" : "No") },
    { label: "Pago con QR habilitado", source: "qrEnabled", transform: (val) => (val ? "Sí" : "No") },
    { label: "Pago con Tarjeta/Tigo Money habilitado", source: "cardPaymentEnabled", transform: (val) => (val ? "Sí" : "No") },
    { label: "Precio Variable", source: "variablePrice", transform: (val) => (val ? "Sí" : "No") },
    { label: "Método", source: "method" },
    { label: "Etiqueta del Servicio", source: "pinned.tag" },
  ];

  return (
    <TopToolbar>
      <Box display="flex" justifyContent="center" width="100%">
        <CreateButton />
         <ExportButton
        onClick={handleExportClick}
        data={exportData}
        columns={columns}
        filename="servicios.xlsx"
        loading={loading}
      />
      </Box>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const List = (props) => (
  <BaseList filters={<TitleFilter />} {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <ImageField source="imageURL" label="Imagen" />
      <TextField source="unitPrice" label="Precio Unitario(Bs.)" />
      <TextField source="description" label="Descripción" />
      <BooleanField source="enabled" label="Habilitado" />
      <BooleanField source="qrEnabled" label="Pago con QR habilitado" />
      <BooleanField source="cardPaymentEnabled" label="Pago con Tarjeta/Tigo Money habilitado" />
      <BooleanField source="variablePrice" label="Precio Variable" />
      <TextField source="method" label="Método" />
      <TextField source="pinned.tag" label="Etiqueta del Servicio" />
    </Datagrid>
  </BaseList>
);

import React, { useState } from "react";
import { Filter, List as BaseList, Datagrid, TextField, DateField, BooleanField, TopToolbar, FilterButton, CreateButton } from "react-admin";
import RegexSearchInput from "../../components/RegexSearchInput";
import ExportButton from "../../components/ExcelExportButton";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import useExport from "../../components/UseExcelExport";

const NameFilter = [
    <RegexSearchInput source="name" label="Nombre" />,
];

const ListActions = (props) => {
  const { getExportData, exportData, loading } = useExport("enterprise/branches");
  const [openModal, setOpenModal] = useState(false);

  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData();
      setOpenModal(false);
    }
  };

  const { className, basePath } = props;

  const columns = [
    { label: "Nombre", source: "name" },
    { label: "Teléfono", source: "addressInfo.phone" },
    { label: "Dirección", source: "addressInfo.street" },
    { label: "Estado", source: "status", transform: (value) => (value ? "habilitado" : "no habilitado") },
  ];

  return (
    <TopToolbar className={className}>
      <FilterButton filters={NameFilter} />
      <CreateButton basePath={basePath} />
      <ExportButton
        onClick={handleExportClick}
        data={exportData}
        columns={columns}
        filename="branches.xlsx"
      />
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const List = (props) => (
  <BaseList {...props} filters={NameFilter} bulkActionButtons={false} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <TextField source="addressInfo.phone" label="Teléfono" />
      <TextField source="addressInfo.street" label="Dirección" /> 
      <BooleanField source="status" label="Estado" /> 
    </Datagrid>
  </BaseList>
);

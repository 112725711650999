// in src/users.js
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Create as BaseCreate,
  ImageField,
  SimpleForm,
  SelectInput,
  TextInput,
  ImageInput,
  NumberInput,
  required,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { SelectInputCategory } from "../Category/SelectInput";
const PREFIX = "BooleanInputs";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseCreate = styled(BaseCreate)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

export const BooleanInputs = () => {
  const { watch, setValue } = useFormContext();
  const variablePrice = watch("variablePrice");
  useEffect(() => {
    setValue("cardPaymentEnabled", false);
    setValue("qrEnabled", false);
  }, [variablePrice, setValue]);
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          <BooleanInput
            validate={[required()]}
            source="variablePrice"
            label="Precio Variable"
            defaultValue={false}
          />
          <BooleanInput
            validate={[required()]}
            source="qrEnabled"
            label="Pago con QR habilitado"
            disabled={formData.variablePrice}
            defaultValue={false}
          />

          <BooleanInput
            validate={[required()]}
            source="cardPaymentEnabled"
            label="Pago con Tarjeta / Tigo Money habilitado"
            disabled={formData.variablePrice}
            defaultValue={false}
          />
        </>
      )}
    </FormDataConsumer>
  );
};

export const PinnedInputs = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          <BooleanInput 
            source="pinned.isPinned" 
            label="Fijar servicio" 
            defaultValue={false} />
          {formData.pinned && formData.pinned.isPinned && 
          <TextInput source="pinned.tag" 
            label="Etiqueta del Servicio Destacado"/>}
        </>
      )}
    </FormDataConsumer>
  );
};

export const Create = ({ ...props }) => {
  return (
    <StyledBaseCreate {...props}>
      <SimpleForm>
        <TextInput validate={[required()]} source="name" label="Nombre" />
        <ImageInput
          validate={[required("La imagen es requerida")]}
          source="imageURL"
          label="Imagen de Servicio"
          accept=".jpeg,.jpg,.png,.jfif"
          placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
        >
          <ImageField source="src" title="Imagen" />
        </ImageInput>
        <TextInput
          validate={[required()]}
          multiline
          source="description"
          label="Descripción"
        />
        <NumberInput
          validate={[required()]}
          source="unitPrice"
          placeholder="Bs."
          label="Precio Unitario"
        />

        <BooleanInputs />

        <NumberInput
          source="availableAfterHours"
          placeholder="horas"
          label="Tiempo de reserva anticipada (horas)"
          validate={[required()]}
          defaultValue={24}
        />

        <SelectInputCategory />

        <SelectInput
          validate={[required()]}
          source="unitEstimatedWorkMinutes"
          label="Tiempo de servicio estimado"
          choices={[
            { id: 0, name: "0 Minutos" },
            { id: 30, name: "30 Minutos" },
            { id: 60, name: "1 Hora" },
            { id: 90, name: "1 Hora 30 Minutos" },
            { id: 120, name: "2 Horas" },
            { id: 150, name: "2 Horas 30 Minutos" },
            { id: 180, name: "3 Horas" },
            { id: 210, name: "3 Horas 30 Minutos" },
            { id: 240, name: "4 Horas" },
            { id: 270, name: "4 Horas 30 Minutos" },
            { id: 300, name: "5 Horas" },
            { id: 330, name: "5 Horas 30 Minutos" },
            { id: 360, name: "6 Horas" },
            { id: 390, name: "6 Horas 30 Minutos" },
            { id: 420, name: "7 Horas" },
            { id: 450, name: "7 Horas 30 Minutos" },
            { id: 480, name: "8 Horas" },
          ]}
        />
        <SelectInput
          validate={[required()]}
          source="method"
          label="Método"
          choices={[
            { id: "En sucursal", name: "En sucursal" },
            { id: "A domicilio", name: "A domicilio" },
            { id: "Ambos", name: "Ambos" },
          ]}
        />
        <TextInput
          source="provider._id"
          defaultValue={JSON.parse(localStorage.getItem("profile"))._id}
          disabled
          style={{ display: "none" }}
        />
        <TextInput
          source="provider.name"
          defaultValue={JSON.parse(localStorage.getItem("profile")).first_name}
          disabled
          style={{ display: "none" }}
        />
        <TextInput
          source="provider.logoURL"
          defaultValue={JSON.parse(localStorage.getItem("profile")).picture}
          disabled
          style={{ display: "none" }}
        />
        <PinnedInputs />
      </SimpleForm>
    </StyledBaseCreate>
  );
};

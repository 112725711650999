import React from "react";
import { styled } from "@mui/material/styles";
import {
  ImageField,
  TextField,
  Edit as BaseEdit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  ImageInput,
  NumberInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRedirect,
  useRefresh,
  FormDataConsumer,
} from "react-admin";
import { BooleanInputs } from "./Create";
const PREFIX = "Edit";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseEdit = styled(BaseEdit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

function formatImage(value) {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("/services"); 
    refresh(); 
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este elemento? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};

export const PinnedInputs = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          <BooleanInput source="pinned.isPinned" label="Fijar servicio" defaultValue={false} />
          {formData.pinned && formData.pinned.isPinned && <TextInput source="pinned.tag" label="Etiqueta del Servicio Destacado" />}
        </>
      )}
    </FormDataConsumer>
  );
};

export const Edit = ({ ...props }) => (
  <StyledBaseEdit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextField source="name" label="Nombre" />
      <ImageInput validate={[required("La imagen es requerida")]} format={formatImage} source="imageURL" label="Imagen de Servicio" accept=".jpeg,.jpg,.png,.jfif" placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}>
        <ImageField source="src" title="Imagen" />
      </ImageInput>
      <TextInput validate={[required()]} multiline source="description" label="Descripción" />
      <NumberInput validate={[required()]} source="unitPrice" placeholder="Bs." label="Precio Unitario" />

      <BooleanInputs />

      <NumberInput source="availableAfterHours" placeholder="horas" label="Tiempo de reserva anticipada (horas)" validate={[required()]} defaultValue={24} />

      <SelectInput
        validate={[required()]}
        source="unitEstimatedWorkMinutes"
        label="Tiempo de servicio estimado"
        choices={[
          { id: 0, name: "0 Minutos" },
          { id: 30, name: "30 Minutos" },
          { id: 60, name: "1 Hora" },
          { id: 90, name: "1 Hora 30 Minutos" },
          { id: 120, name: "2 Horas" },
          { id: 150, name: "2 Horas 30 Minutos" },
          { id: 180, name: "3 Horas" },
          { id: 210, name: "3 Horas 30 Minutos" },
          { id: 240, name: "4 Horas" },
          { id: 270, name: "4 Horas 30 Minutos" },
          { id: 300, name: "5 Horas" },
          { id: 330, name: "5 Horas 30 Minutos" },
          { id: 360, name: "6 Horas" },
          { id: 390, name: "6 Horas 30 Minutos" },
          { id: 420, name: "7 Horas" },
          { id: 450, name: "7 Horas 30 Minutos" },
          { id: 480, name: "8 Horas" },
        ]}
      />
      <SelectInput
        validate={[required()]}
        source="method"
        label="Método"
        choices={[
          { id: "En sucursal", name: "En sucursal" },
          { id: "A domicilio", name: "A domicilio" },
          { id: "Ambos", name: "Ambos" },
        ]}
      />
      <BooleanInput validate={[required()]} source="enabled" label="Habilitado" />
      <PinnedInputs />
    </SimpleForm>
  </StyledBaseEdit>
);

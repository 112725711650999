import React, { useState, useEffect } from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  DateInput,
  useRefresh,
  ArrayField,
  ChipField,
  SingleFieldList,
  useListContext,
  CreateButton,
  FilterButton,
  TopToolbar,
} from "react-admin";
import Paper from "@mui/material/Paper";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import ExportButton from "../components/ExcelExportButton";
import useExport from "../components/UseExcelExport";
import { transform } from "typescript";

const postRowStyle = (record, index) => ({
  backgroundColor: !record?.paymentInfo.isFeePaid ? "#ffdfd4" : "",
});

const MyDatagrid = (props) => {
  let sum = 0;
  let fee = 0;
  let totalFee = 0;
  const { data } = useListContext();
  data?.map((e, index) => {
    sum += e.paymentInfo.totalPrice;
    totalFee += e.paymentInfo.feeAmount;
    if (e.paymentInfo.isFeePaid) {
      fee += e.paymentInfo.feeAmount;
    }
  });

  props.totalIn(sum, totalFee);
  props.commissionPaid(fee);
  return (
    <Datagrid bulkActionButtons={false} rowStyle={postRowStyle} {...props} />
  );
};

const BalanceFilter = [
    <DateInput label="Desde" source="bookingDate>" />,
    <DateInput label="Hasta" source="bookingDate<" />,
]

const ListActions = (props) => {
  const { getExportData, exportData, loading } = useExport("booking/balance");
  const [openModal, setOpenModal] = useState(false);

  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData();
      setOpenModal(false);
    }
  };

  const { className, basePath } = props;

  const columns = [
    { label: "ID", source: "_id" },
    // { label: "Proveedor", source: "provider.name" },
    { label: "Servicios Solicitados", 
      source: "serviceCart", 
      transform: (serviceCart) => (serviceCart ? serviceCart.map((service) => service.service.name).join(", ") : "") },
    { label: "Empleado", source: "employee.fullName" },
    { label: "Fecha de Inicio", source: "bookingDate" },
    { label: "Precio Total", source: "paymentInfo.totalPrice" },
    { label: "Método de Pago", source: "paymentInfo.paymentMethod" },
    // { label: "Estado de Reserva", source: "status" },
    { label: "Estado de Pago", source: "paymentInfo.status" },
    { label: "Porcentaje de comision", source: "paymentInfo.feeRate" },
    { label: "Comisión Pagada", source: "paymentInfo.isFeePaid", transform: (value) => (value ? "Sí" : "No") },
    { label: "Comisión", source: "paymentInfo.feeAmount" },
  ];

  return (
    <TopToolbar className={className}>
      <FilterButton filters={BalanceFilter} />
      <CreateButton basePath={basePath} />
      <ExportButton
        onClick={handleExportClick}
        data={exportData}
        columns={columns}
        filename="ingresos.xlsx"
      />
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress  />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const BalanceList = (props) => {
  let [totalIn, setTotalIn] = useState(0);
  let [totalCom, setTotalCom] = useState(0);
  let [commissionPaid, setCommissionPaid] = useState(0);

  const callbackFunction = (childData, totCom) => {
    setTotalIn(childData);
    setTotalCom((Math.round(totCom * 100) / 100).toFixed(2));
  };

  const callbackCommission = (childData) => {
    setCommissionPaid(childData);
  };

  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(() => refresh(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <List
        sort={{ field: "startDate", order: "ASC" }}
        bulkActionButtons={false}
        filters={BalanceFilter}
        {...props}
        actions={<ListActions />}
      >
        <MyDatagrid
          rowClick="show"
          totalIn={callbackFunction}
          commissionPaid={callbackCommission}
        >
          <TextField source="_id" label="ID" />
          <ArrayField source="serviceCart" label="Servicios Solicitados">
            <SingleFieldList>
              <ChipField source="service.name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="employee.fullName" label="Empleado" />
          <DateField
            source="bookingDate"
            label="Fecha de Inicio"
            showTime
            locales="es-ES"
          />
          <TextField
            source="paymentInfo.totalPrice"
            label="Precio Total (Bs.)"
          />
          <TextField
            source="paymentInfo.paymentMethod"
            label="Método de Pago"
          />
          <TextField source="paymentInfo.status" label="Estado de Pago" />
          <TextField source="paymentInfo.feeRate" label="% de Comisión" />
          <TextField source="paymentInfo.feeAmount" label="Comisión (Bs.)" />
          <BooleanField
            source="paymentInfo.isFeePaid"
            label="Comisión pagada"
          />
        </MyDatagrid>
      </List>
      <div>
        <Paper>
          <h3>Ingresos Totales: Bs.{totalIn}</h3>
          <h3>Comisiones Totales: Bs.{totalCom}</h3>
        </Paper>
        <Paper>
          <h3>Comisiones Totales: Bs.{totalCom}</h3>
          <h3>Comisiones Pagadas: Bs.{commissionPaid.toFixed(2)}</h3>
          <h3>
            Comisiones en deuda: Bs.{(totalCom - commissionPaid).toFixed(2)}
          </h3>
        </Paper>
      </div>
    </div>
  );
};
